<template>
  <div class="wrapper login-wrapper">
    <div class="container flex-col items-center">
      <div
        class="flex flex-col items-center w-9/12 mt-12 p-5 login-container shadow rounded-gwdfull"
        v-if="!success"
      >
        <h3 class="mt-4">Unustasid parooli?</h3>
        <p class="mb-8">
          Saadame sinu meilile kohe lingi, mille abil saad oma parooli ära
          vahetada!
        </p>
        <h4>Meiliaadressi</h4>
        <input
          type="text"
          v-model="email"
          class="mb-8 w-10/12 bg-offwhite shadow focus:shadow-outline"
        />
        <div class="error-box w-10/12 mb-4" v-if="error">
          <div class="flex w-1/12">
            <span class="typcn typcn-times text-2xl leading-none"></span>
          </div>
          <div class="flex w-11/12">
            <span v-if="wrongEmail"
              >Selle meiliaadressiga kontot ei eksisteeri</span
            >
          </div>
        </div>
        <button @click="resetPasswordRequest()" class="new-button-green">
          <span class="typcn typcn-mail icon"></span>
          <span class="label">Saada paroolivahetuse kinnitus</span>
        </button>
      </div>
      <div class="flex flex-col" v-if="success">
        <div
          class="flex flex-col bg-white rounded-md hard-shadow mt-12 p-5 w-9/12 mx-auto items-center justify-center"
        >
          <h3 class="mb-3">Kontrollige meili saabumist.</h3>
          <p class="mx-5 text-lg">
            Juhul, kui te kirja ei leia, kontrollige oma rämpsposti kausta või
            vajutage
            <span
              class="hover:text-green cursor-pointer"
              @click="resetPasswordRequest()"
              >siia</span
            >, et kiri uuesti saata.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  data() {
    return {
      email: "",
      success: false,
      wrongEmail: false,
      error: false
    };
  },
  mixins: [RequestHandler],
  methods: {
    resetPasswordRequest() {
      const data = {
        email: this.email
      };
      this.apiRequest("password_reset/", "post", false, data).then(
        res => {
          res;
          this.success = true;
        },
        err => {
          let errObj = { err };
          this.wrongEmail = errObj.err.response.data.errors.email;
          this.error = true;
        }
      );
    }
  }
};
</script>
